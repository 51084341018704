<template>
  <div
    v-if="isNeedBanner"
    class="d-flex justify-center align-center access-alert-message">
    <v-icon
      size="32"
      class="mr-3"
      color="mainYellow">
      mdi-alert
    </v-icon>
    <p class="mb-0 mr-3">
      {{ alertMessage }}
    </p>
    <v-btn
      color="lightBlue"
      :to="{ name: 'payment' }">
      Manage
    </v-btn>
    <v-icon
      class="close-alert-icon"
      size="24"
      @click="showAlert = false">
      mdi-closes
    </v-icon>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { TYPE_OWNER } from '@/constants/userPermissions';
export default {
  name: 'AppBannerManagePayment',
  data: () => ({
    showAlert: true,
  }),
  computed: {
    ...mapState(['role']),
    ...mapState('Workspace', ['userPremium']),
    isNeedBanner() {
      return (TYPE_OWNER !== this.role) && (this.$route.name === 'schedule-library') && this.alertMessage && this.showAlert;
    },
    alertMessage() {
      if (!this.userPremium) {
        return null;
      }
      return `You were invited to become ${this.role}, please upgrade
        to be granted access to the full functionality of this role.`;
    },
  },
};
</script>
